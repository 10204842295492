import { shuffle } from "./lib/shuffle";
import { GameType, GameMode, GameState } from "./GameManager";
import { Group } from "./GameState";

abstract class GameLogic {
	mode: GameMode
	state: GameState
	secretNumber: number

	constructor(mode: GameMode, state: GameState) {
		this.mode = mode;
		this.state = state;
	}

	static initGame(type: GameType, mode: GameMode, state: GameState) {
		let game: GameLogic;
		if (type == GameType.GameA) {
			game = new GameLogicA(mode, state);
		}
		else if (type == GameType.GameB) {
			game = new GameLogicB(mode, state);
		}
		game.init();
		return game;
	}

	private init() {
		let state = this.state;
		let size = state.size;

		if (this.mode == GameMode.AppPicks || this.mode == GameMode.LearningMode) {
			this.secretNumber = Math.ceil(Math.random() * size);
		}
		else {
			this.secretNumber = null;
		}

		let numbers: number[] = [];
		for (let i = 1; i <= size; i++) {
			numbers.push(i);
		}

		if (this.mode !== GameMode.LearningMode) {
			shuffle(numbers);
		}

		state.groups = this.getGroups(numbers);

		state.introGroup = {
			bit: null,
			colors: [],
			cells: numbers.map(n => {
				return {
					number: n,
					color: 'white',
				}
			})
		};

		state.answers = [];

		this.setStep(0);
	}

	protected abstract getGroups(numbers: number[]): Group[];

	reset() {
		this.init();
	}

	recordAnswer(answer?: number) {
		if (typeof answer == 'number') this.state.answers.push(answer);

		if (this.state.currentStep >= this.state.groups.length - 1) {
			return true;
		}
		else {
			this.setStep(this.state.currentStep + 1);
			return false;
		}
	}

	protected setStep(step: number) {
		this.state.currentStep = step;
		this.state.currentGroup = this.state.groups[step];
	}

	computeAnswer() {
		this.state.finalAnswer = this.state.answers.reduce((a, b) => a + b) + 1;
	}
}

class GameLogicA extends GameLogic {
	getGroups(numbers: number[]) {
		let colors = shuffle([
			['pink', 'red'],
			['light blue', 'blue'],
			['gray', 'green'],
			['white', 'black'],
			['yellow', 'purple'],
			['orange', 'brown'],
		]);

		let bits = [1, 2, 4, 8, 16, 32]; // [32, 16, 8, 4, 2, 1];

		return bits.map((bit, i) => ({
			bit: bit,
			colors: colors[i],
			cells: numbers.map(n => {
				let flag = ((n - 1) & bit) ? 1 : 0;
				return {
					number: n,
					color: colors[i][flag],
				}
			})
		}));
	}
}

class GameLogicB extends GameLogic {
	getGroups(numbers: number[]) {
		let colors = [
			'red',
			'blue',
			'green',
			'yellow'
		];

		let rounds = /* [1, 4, 16] */ [16, 4, 1];

		return rounds.map((bit, i) => ({
			bit: bit,
			colors: colors,
			cells: numbers.map(n => {
				let flag = (((n - 1) / bit) & 3); // (((n - 1) >> (i * 2)) & 3);
				return {
					number: n,
					color: colors[flag],
				}
			})
		}));
	}
}

export { GameLogic, GameState }
