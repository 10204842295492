import { GameLogic } from "./GameLogic";
import { GameState, createEmptyState } from "./GameState";

type MetaState = 'menu' | 'before' | 'during' | 'after'

const enum GameType {
	 /** There are six grids with two colors each. The colors may vary from grid to grid */
	GameA = 1,
	/** There are three grids with four colors each.  The colors are the same for each grid and the order matters */
	GameB,
}

const enum GameMode {
	/** The player picks the number and states the colors, and the game predicts the number */
	PlayerPicks = 1,
	/** One player picks and calls out the colors, while the other listens and predicts the number */
	TwoPlayer,
	/** The game will choose a number and tell you colors so you can practice */
	AppPicks,
	/** The game will teach you how to predict */
	LearningMode,
}

interface _GameManagerState {
	metaState: MetaState
	gameType: GameType
	gameMode: GameMode
}

type GameManagerState = GameState & _GameManagerState;

class GameManager {
	state: GameManagerState
	logic: GameLogic

	callback: () => void

	constructor(callback: () => void) {
		this.callback = callback || (() => {});
		let managerState: _GameManagerState = {
			metaState: 'menu',
			gameType: null,
			gameMode: null,
		};

		this.state = Object.assign(createEmptyState(), managerState);
		this.logic = null;
	}

	begin = () => {
		this.state.metaState = 'during';
		this.callback();
	}

	private end() {
		if (this.state.gameMode == GameMode.PlayerPicks) {
			this.logic.computeAnswer();
		}

		this.state.metaState = 'after';
	}

	reset = (allTheWay = false) => {
		if (allTheWay) {
			this.state.gameType = null;
			this.state.gameMode = null;
			this.state.metaState = 'menu';
		}
		else {
			this.init();
		}
		this.callback();
	}

	selectGameA = () => {
		this.setGameType(GameType.GameA);
	}

	selectGameB = () => {
		this.setGameType(GameType.GameB);
	}

	resetGameType = () => {
		this.setGameType(null);
	}

	private setGameType(type: GameType) {
		this.state.gameType = type;
		this.callback();
	}

	selectPlayerPicks = () => {
		this.setGameMode(GameMode.PlayerPicks);
	}

	selectTwoPlayer = () => {
		this.setGameMode(GameMode.TwoPlayer);
	}

	selectLearningMode = () => {
		this.setGameMode(GameMode.LearningMode);
	}

	selectAppPicks = () => {
		this.setGameMode(GameMode.AppPicks);
	}

	private setGameMode(mode: GameMode) {
		this.state.gameMode = mode;
		this.init();
		this.callback();
	}

	resetGameMode = () => {
		this.state.gameMode = null;
		this.callback();
	}

	init() {
		let state = this.state;
		this.logic = GameLogic.initGame(state.gameType, state.gameMode, state);

		state.metaState = 'before';
	}

	// nextStep gets called directly by a click handler, so it can't call recordAnswer directly since it passes an event
	nextStep = () => {
		this.recordAnswer();
	}

	recordAnswer(answer?: number) {
		let ended = this.logic.recordAnswer(answer);
		if (ended) {
			this.end();
		}
		this.callback();
	}
}


export { GameManager, GameState, GameType, GameMode }
