/** Note: this is an in-place shuffle.  If you don't want to modify
 * the original array, call slice() on it first: `shuffle(origArray.slice())`
 */
function shuffle<T>(array: T[]): T[] {
	let m = array.length, t, i;

	while (m) {
		i = Math.floor(Math.random() * m--);
		t = array[m];
		array[m] = array[i];
		array[i] = t;
	}

	return array;
}

export { shuffle }
