import * as React from 'react';
import { GameManager, GameMode, GameType } from './GameManager';
import { Grid } from './Grid';

interface GameIntroProps {
	game: GameManager
}

const GameIntro: React.FunctionComponent<GameIntroProps> = (props) => {
	let game = props.game;
	return (
		<div>
			<Grid group={game.state.introGroup} width={game.state.width} />

			{game.state.gameMode == GameMode.PlayerPicks && <>
				<p>In this game, you will pick a number, and I will discern it by the powers of... magic.</p>

				<p>Choose any number from the grid above.  Remember it while we play.</p>

				<p>
					<button autoFocus onClick={game.begin}>I have my number. Let's Go!</button>
				</p>
			</>}

			{game.state.gameMode == GameMode.AppPicks && <>
				<p>
					In this game, I, the computer, will pick a number, and will tell you what colors it is.
					You will then use your powers of deduction to tell me what the number was!
				</p>

				<p>Ready when you are!</p>

				<p>
					<button autoFocus onClick={game.begin}>Let's begin!</button>
				</p>
			</>}

			{game.state.gameMode == GameMode.TwoPlayer && <>
				<p>
					Pick a number and remember it, but don't tell me what it is!
				</p>

				<p>
					<button autoFocus onClick={game.begin}>Ready</button>
				</p>
			</>}

			{game.state.gameMode == GameMode.LearningMode && <>
				<p>
					I will teach you how to determine the secret number by asking what color the number is.
				</p>

				<p>Normally, the numbers will be shuffled, but this time we'll keep them in order.</p>

				<p>Each round you will add a number based on which color they choose.</p>

				<p>
					You will start with the number 0 in your head.
					Then each round, depending on which color your player chooses, you will
					add something to your number.
				</p>

				{/*
				{game.state.gameType == GameType.GameA && <>
					<p>
						In this version on the game, the colors don't matter - only the order of them.
					</p>

					<p>
						Each set of colors has a light and a dark color.
						When the number is a light color, you don't need to do anything.
						When the color is a dark color, you add a number for the current round.
					</p>

					<p>
						In the first round, you'll add 1 if the color is dark.
					</p>
					<p>
						In the second round, you'll add 2 if the color is dark.
					</p>
					<p>
						In the third round, you'll add 4 if the color is dark.
					</p>
					<p>
						In the fourth round, you'll add 8 if the color is dark.
					</p>
					<p>
						In the fifth round, you'll add 16 if the color is dark.
					</p>
					<p>
						In the sixth round, you'll add 32 if the color is dark.
					</p>
				</>}

				{game.state.gameType == GameType.GameB && <>
					<p>
						Red is always 0 (don't add anything). Yellow adds the most.
						You can remember the order by the length of the color names: red is shortest (3 letters), blue is next,
						then green, and yellow is the longest (6 letters).
					</p>

					<p>
						In the first round, you'll add a multiple of 16 – either 0, 16, 32, or 48.
					</p>
					<p>
						In the second round, you'll add a multiple of 4 – 0, 4, 8, or 12.
					</p>
					<p>
						In the third round, you'll add a multiple of 1 – 0, 1, 2, or 3.
					</p>
				</>}

				<p>
					At the end, you will add 1 to your number.
					This will give you a number between 1 and 64.
					This will be the number the player chose.
				</p>
				*/}

				<p>
					At the end, you will be able to tell them the number they chose!
				</p>

				<p><strong>Don't worry, I'll walk you through it step by step.</strong></p>

				<p>Hit 'Ready' to begin.</p>

				<p>
					<button autoFocus onClick={game.begin}>Ready</button>
				</p>
			</>}
		</div>
	);
}

export { GameIntro }
