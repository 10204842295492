import * as React from 'react';

import { GameManager } from './GameManager';
import { GameLayout } from './GameLayout';
import { GameMenu } from './GameMenu';
import { GameIntro } from './GameIntro';
import { GameMain } from './GameMain';
import { GameEnd } from './GameEnd';

interface GameProps {}

class GameComponent extends React.Component<GameProps, {}> {
    game: GameManager;

    componentWillMount() {
        this.game = new GameManager(() => this.forceUpdate());
        (window as any).game = this.game;
    }

    render() {
        let game = this.game;
        let metaState = game.state.metaState;

        let content;


        switch (metaState) {
            case 'menu': content = <GameMenu game={game} />; break;
            case 'before': content = <GameIntro game={game} />; break;
            case 'during': content = <GameMain game={game} />; break;
            case 'after': content = <GameEnd game={game} />; break;
        }

        return <GameLayout game={game}>{content}</GameLayout>
     }
};

export { GameComponent }
