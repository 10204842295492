import * as React from 'react';
import { GameManager, GameMode } from './GameManager';

interface GameEndProps {
	game: GameManager
}

const GameEnd: React.FunctionComponent<GameEndProps> = (props) => {
	let [guess, setGuess] = React.useState('');
	let [guessed, setGuessed] = React.useState(false);

	let game = props.game;

	let correct = false;
	if (guessed) {
		correct = +guess == game.logic.secretNumber;
	}

	let n = game.logic.secretNumber - 1;
	let components = game.logic.state.groups.map(group =>
		n & (group.bit * (group.colors.length - 1))
	);

	let hideButtons = game.state.gameMode == GameMode.AppPicks && !guessed;

	return (
		<div>
			{game.state.gameMode == GameMode.PlayerPicks && <>
				<h2 className="answer">
					Your number was {game.state.finalAnswer}.
				</h2>
				<p>Wasn't that awesome?</p>
			</>}

			{game.state.gameMode == GameMode.TwoPlayer && <>
				<h2 className="answer">
					The other player should
					<br />tell you your number now.
				</h2>
				<p>Wasn't that awesome?</p>
			</>}

			{game.state.gameMode == GameMode.AppPicks && <>
				{guessed
					? <>
						<h2 className="answer">
							{correct ? 'Correct!' : 'Wrong, it was ' + game.logic.secretNumber}
						</h2>
						<p>
							{(correct)
								? "Wasn't that awesome?"
								: <>Try again, or brush up on your skills with <a href="javascript:" onClick={() => {game.selectLearningMode();}}>Learning Mode</a>.</>
							}
						</p>
					</>
					: <>
						<h2 className="answer">
							What was my number?
						</h2>
						<form onSubmit={() => setGuessed(true)}>
							<input type="tel" pattern="[0-9]*" /* type="number" */ autoFocus
								style={{fontSize: 24, padding: 10, borderRadius: 6, width: 120}}
								value={guess}
								onChange={e => setGuess(e.target.value.replace(/\D/g, ''))}
							/>
							<button>Guess</button>
						</form>
					</>
				}
			</>}

			{game.state.gameMode == GameMode.LearningMode && <>
				<p>
					You now have a number that could be as low as 0 and as high as 63.
					The numbers on the board are from 1 to 64.
				</p>
				<p>
					The last step is to add 1 to your number.
					This will be the number that your player chose!
				</p>

				<p>
					For this round, you should have
					{components.map((num, i) => <React.Fragment key={i}> <strong>{num}</strong> + </React.Fragment>)}
					<strong>1</strong> = <strong>{game.logic.secretNumber}</strong>.
				</p>

				<p>
					Hit "Play Again" to keep practicing until you get the hang of it.
					Or, try <a href="javascript:" onClick={() => {game.selectAppPicks();}}>Practice Mode</a>
					{' '} to start practicing.
				</p>
			</>}

			{!hideButtons &&
				<p>
					<button autoFocus onClick={() => game.reset(false)}>Play Again</button>
					<button onClick={() => game.reset(true)}>Back</button>
				</p>
			}
		</div>
	);
};

export { GameEnd }
