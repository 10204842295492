import * as React from 'react';
import { GameManager } from './GameManager';

interface GameMenuProps {
	game: GameManager
}

const GameMenu: React.FunctionComponent<GameMenuProps> = (props) => {
	let game = props.game;
	return (
		<div>
			<p className="wide">
				This is a game, that's also a magic trick.
				You can play it against the computer, try to
				figure out how it works, and try to do it yourself.
			</p>

			<p className="wide">
				Or, you can learn to do it and try it on your friends,
				who will be 100% impressed by your dazzling intellect,
				once you get it down.
			</p>

			<h2>Choose your game:</h2>

			{!game.state.gameType ? <>
				<ul className="vert-list">
					<li>
						<button className="menuButton" onClick={game.selectGameB}>Game A</button>
						<p>Four colors. Recommended.</p>
					</li>
					<li>
						<button className="menuButton" onClick={game.selectGameA}>Game B</button>
						<p>Two colors. Also Interesting.</p>
					</li>
				</ul>
			</>: <>
				<ul className="vert-list">
					<li>
						<button className="menuButton" onClick={game.selectPlayerPicks}>Try it out</button>
						<p>Choose a number, and the computer will guess it.</p>
					</li>
					<li>
						<button className="menuButton" onClick={game.selectLearningMode}>Learn{/*  to predict */}</button>
						<p>Learn how to guess the number yourself.</p>
					</li>
					<li>
						<button className="menuButton" onClick={game.selectAppPicks}>Practice{/* 1 Player (app picks the number, you predict) */}</button>
						<p>Practicing guessing a number picked by the computer.</p>
					</li>
					<li>
						<button className="menuButton" onClick={game.selectTwoPlayer}>2 Player</button>
						<p>Once you have it down, show off to your friends.</p>
					</li>
				</ul>
			</>}
		</div>
	);
}

export { GameMenu }
