type Color = string

interface Cell {
	color: Color
	number: number
}

interface Group {
	bit: number
	cells: Cell[]
	colors: string[]
}

interface GameState {
	size: number
	steps: number
	width: number
	numbers: number
	groups: Group[]
	currentStep: number
	introGroup: Group
	currentGroup: Group
	answers: number[]
	finalAnswer: number
}

function createEmptyState(): GameState {
	return {
		size: 64,
		steps: null,
		width: 8,
		groups: null,
		currentStep: 0,
		currentGroup: null,
		introGroup: null,
		numbers: null,
		answers: [],
		finalAnswer: 0,
	}
}


export { GameState, Group, createEmptyState }
