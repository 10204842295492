import * as React from 'react';
import { GameManager, GameMode, GameType } from './GameManager';
import { Grid } from './Grid';

interface GameMainProps {
	game: GameManager
}

const GameMain: React.FunctionComponent<GameMainProps> = (props) => {
	let game = props.game;
	let state = game.state;
	// let logic = game.logic;
	let currentGroup = state.currentGroup;

	let showPicker = state.gameMode == GameMode.PlayerPicks;
	let showInstructions = state.gameMode == GameMode.LearningMode;

	console.log('GameMain', props);

	let currentPickedColor = null;
	let currentColorIndex = 0;
	let secretNumber = game.logic.secretNumber;
	if (secretNumber > 0) {
		currentColorIndex = (((secretNumber - 1) / currentGroup.bit) & (currentGroup.colors.length - 1));
		currentPickedColor = currentGroup.colors[currentColorIndex];
	}

	return (
		<div className="GameMain">
			<Grid group={currentGroup} width={state.width} />

			{state.gameMode == GameMode.TwoPlayer && <>
				<p>What color is your number?</p>
			</>}

			{showInstructions && <>
				{state.gameType == GameType.GameA && <>
					<p>
						In this round, you will add the number {currentGroup.bit} to your number
						if the other player chooses a dark color.
						So if they say {currentGroup.colors[0]},
						do nothing, but if they say {currentGroup.colors[1]},
						add {currentGroup.bit} and
						remember the result for the next round.
					</p>
				</>}

				{state.gameType == GameType.GameB && <>
					<p>
						<strong>In this round,</strong> you will add either
						0, {currentGroup.bit}, {currentGroup.bit * 2},
						or {currentGroup.bit * 3} to your number,
						depending on what color the other player says.
					</p>

					<p>
						So if they say {currentGroup.colors[0]}, add 0 (in other words, the number stays the same).<br/>
						If they say {currentGroup.colors[1]}, add {currentGroup.bit}.<br/>
						If they say {currentGroup.colors[2]}, add {currentGroup.bit * 2}.<br/>
						If they say {currentGroup.colors[3]}, add {currentGroup.bit * 3}.<br/>
					</p>

					<p>
						<strong>TIP:</strong> You can remember which color is which number by number of letters in their names:
						Red (3 letters) adds the least, followed by blue (4 letters), green (5 letters), and yellow (6 letters) adds the most.
					</p>
				</>}

				<hr/>

				<p>
					Say to the other player, "What color is your number?",
					then follow the instructions below to add to the number in your head.
				</p>

				<p>
					Your imaginary partner says their number is <strong>{currentPickedColor}</strong>,
					so add {currentGroup.bit * currentColorIndex}.
				</p>
			</>}

			{state.gameMode == GameMode.AppPicks && <p>
				I'm thinking of a number, and it's a <strong>{currentPickedColor}</strong> number.
			</p>}

			{showPicker && <>
				<p>Which color is your number?</p>
				{currentGroup.colors.map((color, i) =>
					<button key={color} className={'btn ' + color.replace(' ', '-')}
						onClick={() => { game.recordAnswer(i * currentGroup.bit) }}
					>
						{color}
					</button>
				)}
			</>}

			{!showPicker &&
				<p>
					<button autoFocus onClick={game.nextStep}>Next</button>
				</p>
			}
		</div>
	);
};

export { GameMain }
