import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { GameComponent } from './GameComponent';

ReactDOM.render(<GameComponent />, document.getElementById('container'));

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register('/sw.js')
	.then(function() { console.log("Service Worker Registered"); });
}
