import * as React from 'react';
import { GameManager, GameType } from './GameManager';

interface GameLayoutProps {
	game: GameManager
}

const GameLayout: React.FunctionComponent<GameLayoutProps> = (props) => {
	let gameClass = props.game.state.gameType == GameType.GameA ? 'gameA' : 'gameB';

	return (
		<div className={`GameLayout ${gameClass}`}>
			<a onClick={e => {props.game.reset(true); e.preventDefault();}} href="/"><h1>sixty-four</h1></a>
			<div className="inner">{props.children}</div>
		</div>
	);
}

export { GameLayout }
