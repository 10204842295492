import * as React from 'react';
import { Group } from './GameState';


interface GridProps {
	group: Group
	width: number
}

const Grid: React.FunctionComponent<GridProps> = (props) => {
	let currentGroup = props.group;
	let width = props.width;

	let rows = [];
	for (let row = 0; row < width; row++) {
		rows.push(currentGroup.cells.slice(row * width, (row + 1) * width));
	}

	return (
		<div className="Grid">
			{rows.map((row, r) =>
				<div key={r} className="row">
					{row.map((cell, c) => (
						<div key={c} className={'cell ' + cell.color.replace(' ', '-')}>
							{cell.number}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export { Grid }
